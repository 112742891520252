body {
    max-width: 100% !important;
    padding: 0;
}

input[type="file"]::-webkit-file-upload-button {
    display: block;
}
.navbar {
    padding: 0;
}

.navbar-subcategories {
    background-color: lightgrey !important;
}

.nav-item.active {
    background-color: lightgrey !important;
}

.form-inline.form-group {
    margin-right: 10px;
}

.form-inline.form-group label {
    margin-right: 10px;
}

.action_group div {
    display: inline-block;
}

.link_remove {
    float:right;
}

.form_add_link {
    clear: both;
}